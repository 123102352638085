/* gbt = game board tile */
.emwd-gbt {
  position: relative;
  display: inline-block;
  margin: 0 !important;
  padding: 0;
  height: 32px;
  width: 32px;
  min-width: 32px;
  min-height: 32px;
  max-width: 32px;
  max-height: 32px;
  background-color: transparent;
  /* If border width is adjusted, make sure to
  adjust TILE_DEFAULT_BORDER_WIDTH in Tile.js */
  border: 0px solid black;
  box-sizing: border-box;
  font-size: 10px;
  line-height: 1;
  transition: all 0s;
} 

.emwd-gbt div {
  transition: all 0s;
}

.emwd-gbtte {
  border-top: 0px solid black;
}

.emwd-gbtle {
  border-left: 0px solid black;
}

.emwd-gbtre {
  border-right: 0px solid black;
}

.emwd-gbtbe {
  border-bottom: 0px solid black;
}
