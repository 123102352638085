/* gbb = game board baord */
.emwd-gbb {
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  line-height: 0;
  background-color: lightgray;
  transition: all ease 1s;
  box-shadow: 0 20px 37px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}

.emwd-gcollectible {
  position: absolute;
  margin: 0;
  padding: 0;
  z-index: 10;
  top: 1px;
  left: 8px;
}

* {
  transition: all ease 1s;
}