.emwd-gao {
  display: block;
  border: 2px solid rgb(71, 177, 71);
  border-radius: 3px;
  height: 50px;
  padding: 13px;
  background-color: rgb(113, 87, 209);
  width: 100% !important;
  cursor: pointer;
}

.emwd-gao label {
  font-size: 20px !important;
  color: white !important;
}

.emwd-gao:hover {
  background-color: rgb(0, 153, 255);
}

#emwd-gobi-main-menu button,
#emwd-meet-chars + button,
#emwd-gobi-how-to-play + button,
body > div.ui.page.modals.dimmer.transition.visible.active > div > div.content > button {
  box-shadow: none !important;
  border: none !important;
  background-color: transparent !important;
  background-position-x: center;
  transition: all ease 250ms;
}

#emwd-gobi-main-menu button:hover,
#emwd-meet-chars + button:hover,
#emwd-gobi-how-to-play + button:hover,
body > div.ui.page.modals.dimmer.transition.visible.active > div > div.content > button:hover {
  background-color: transparent;
  transform: scale(1.1, 1.1);  
}

.ui.dimmer {
  background-color: rgba(0,0,0,.5) !important;
}

body > div.ui.page.modals.dimmer.transition.visible.active {
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

body > div.ui.page.modals.dimmer.transition.visible.active > div {
  display: flex !important;
  padding-top: 5%;
}


body > div.ui.page.modals.dimmer.transition.visible.active,
body > div.ui.page.modals.dimmer.transition.visible.active > div,
body > div.ui.page.modals.dimmer.transition.visible.active > div > div.content,
body > div.ui.page.modals.dimmer.transition.visible.active > div > div.content > div,
body > div.ui.page.modals.dimmer.transition.visible.active > div > div.content > div > div {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: -webkit-flex !important;
  display: flex !important;
}